import React from 'react'

import styles from './notFound.module.css'

export const NotFound404: React.FC = () => {
  return (
    <div className={styles.container}>
      <h3>Sorry, couldn't find that page.</h3>
    </div>
  )
}
