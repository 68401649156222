import React, { useEffect, useContext } from 'react'

import { myContext } from '../provider'

import Layout from '../components/layout/Layout'
import { SEO } from '../components/SEO'
import { NotFound404 } from '../components/404/NotFound404'

const NotFound: React.FC = () => {
  const context = useContext(myContext)
  useEffect(() => {
    context.updateActivePage('404')
  }, [context])

  return (
    <>
      <Layout>
        <SEO title={'Not Found'} />
        <NotFound404 />
      </Layout>
    </>
  )
}

export default NotFound
